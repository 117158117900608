// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.experience{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    height: 30vh;
    padding-top: 5rem;
    margin-bottom: 8rem;
}

.achievement{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.circle{
    width: 4rem;
    height: 4rem;
    background: white;
    border-radius: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: black;
}

.circle::before{
    border-radius: 100%;
    content: '';
    background-image: linear-gradient(to bottom, #87e6fb 0%, #ffc05c 100%);
    top: -8px;
    left: -8px;
    bottom: -8px;
    right: -8px;
    position: absolute;
    z-index: -1;
    box-shadow: var(--boxShadow);
}

.achievement span:nth-of-type(2){
    color: var(--orange);
}

@media screen and (max-width: 480px) {
    .experience{
        gap: 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Experience/Experience.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,sEAAsE;IACtE,SAAS;IACT,UAAU;IACV,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,4BAA4B;AAChC;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI;QACI,SAAS;IACb;AACJ","sourcesContent":[".experience{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 5rem;\n    height: 30vh;\n    padding-top: 5rem;\n    margin-bottom: 8rem;\n}\n\n.achievement{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.circle{\n    width: 4rem;\n    height: 4rem;\n    background: white;\n    border-radius: 100%;\n    position: relative;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 1.5rem;\n    font-weight: bold;\n    margin-bottom: 2rem;\n    color: black;\n}\n\n.circle::before{\n    border-radius: 100%;\n    content: '';\n    background-image: linear-gradient(to bottom, #87e6fb 0%, #ffc05c 100%);\n    top: -8px;\n    left: -8px;\n    bottom: -8px;\n    right: -8px;\n    position: absolute;\n    z-index: -1;\n    box-shadow: var(--boxShadow);\n}\n\n.achievement span:nth-of-type(2){\n    color: var(--orange);\n}\n\n@media screen and (max-width: 480px) {\n    .experience{\n        gap: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
