// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card{
    width: 11rem;
    height: 13rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    text-align: center;
    background: rgba(255,255,255,0.26);
    border: 7px solid var(--orangeCard);
    box-shadow: var(--boxShadow);
    border-radius: 20px;
    padding: 0px 20px 2rem 20px;
}

.card span:nth-of-type(2){
    color: var(--gray);
    font-size: 14px;
}

.card>img{
    transform: scale(0.6);
    margin-bottom: -2rem;
}

.c-button{
    background: #ffffff;
    box-shadow: 0px 19px 60px rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    border: none;
    padding:10px;
    font-size: 16px;
    color: #5290FD;
}

@media screen and (max-width: 480px) {
    .card{
        width: 70%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Card/Card.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;IACnB,kBAAkB;IAClB,kCAAkC;IAClC,mCAAmC;IACnC,4BAA4B;IAC5B,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,6CAA6C;IAC7C,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,cAAc;AAClB;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".card{\n    width: 11rem;\n    height: 13rem;\n    position: absolute;\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n    align-items: center;\n    text-align: center;\n    background: rgba(255,255,255,0.26);\n    border: 7px solid var(--orangeCard);\n    box-shadow: var(--boxShadow);\n    border-radius: 20px;\n    padding: 0px 20px 2rem 20px;\n}\n\n.card span:nth-of-type(2){\n    color: var(--gray);\n    font-size: 14px;\n}\n\n.card>img{\n    transform: scale(0.6);\n    margin-bottom: -2rem;\n}\n\n.c-button{\n    background: #ffffff;\n    box-shadow: 0px 19px 60px rgba(0, 0, 0, 0.08);\n    border-radius: 7px;\n    border: none;\n    padding:10px;\n    font-size: 16px;\n    color: #5290FD;\n}\n\n@media screen and (max-width: 480px) {\n    .card{\n        width: 70%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
