// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services{
    padding: 0rem 3rem 0 3rem;
    display: flex;
    height: 90vh;
    margin-bottom: 8rem;
    margin-top: 7rem;
    padding-top: 3rem;
    
}

.awesome{
    display: flex;
    flex-direction: column;
    position: relative;
}

.awesome>:nth-child(1){
    color: var(--black);
    font-size: 2.5rem;
    font-weight: bold;
}

.awesome>:nth-child(2){
    color: var(--orange);
    font-size: 2.5rem;
    font-weight: bold;
}

.awesome>:nth-child(3){
    color: var(--gray);
    font-size: 14px;
    margin-top: 1rem;

}

.s-button{
    width: 8rem;
    height: 2rem;
    margin-top: 1rem;
}

.cards{
    position: relative;
}

.cards>*{
    position: absolute;
}

/* blur */
.s-blur2{
    left: 14rem;
    top: 8rem;
}

.s-blur1{
    top: 13rem;
    left: -18rem;
}

@media screen and (max-width: 480px) {
    .services{
        margin-top: 0;
        flex-direction: column;
        gap: 5rem;
        height: 66rem;
        padding: 0;
    }
    .cards{
        display: flex;
        flex-direction: column;
        gap: 17rem;
    }
    .cards>*{
        position: static;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Services/Services.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;;AAErB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;IACpB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;;AAEpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA,SAAS;AACT;IACI,WAAW;IACX,SAAS;AACb;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,SAAS;QACT,aAAa;QACb,UAAU;IACd;IACA;QACI,aAAa;QACb,sBAAsB;QACtB,UAAU;IACd;IACA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".services{\n    padding: 0rem 3rem 0 3rem;\n    display: flex;\n    height: 90vh;\n    margin-bottom: 8rem;\n    margin-top: 7rem;\n    padding-top: 3rem;\n    \n}\n\n.awesome{\n    display: flex;\n    flex-direction: column;\n    position: relative;\n}\n\n.awesome>:nth-child(1){\n    color: var(--black);\n    font-size: 2.5rem;\n    font-weight: bold;\n}\n\n.awesome>:nth-child(2){\n    color: var(--orange);\n    font-size: 2.5rem;\n    font-weight: bold;\n}\n\n.awesome>:nth-child(3){\n    color: var(--gray);\n    font-size: 14px;\n    margin-top: 1rem;\n\n}\n\n.s-button{\n    width: 8rem;\n    height: 2rem;\n    margin-top: 1rem;\n}\n\n.cards{\n    position: relative;\n}\n\n.cards>*{\n    position: absolute;\n}\n\n/* blur */\n.s-blur2{\n    left: 14rem;\n    top: 8rem;\n}\n\n.s-blur1{\n    top: 13rem;\n    left: -18rem;\n}\n\n@media screen and (max-width: 480px) {\n    .services{\n        margin-top: 0;\n        flex-direction: column;\n        gap: 5rem;\n        height: 66rem;\n        padding: 0;\n    }\n    .cards{\n        display: flex;\n        flex-direction: column;\n        gap: 17rem;\n    }\n    .cards>*{\n        position: static;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
