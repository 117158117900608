// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle{
    display: flex;
    justify-content: space-between;
    border: 3px solid var(--orange);
    border-radius: 1rem;
    position: relative;
    padding: 2px;
    cursor: pointer;
}

.toggle>*{
    width: 1rem;
    height: 1rem;
    color: var(--orange);
}

.t-button{
    border-radius: 100%;
    background: var(--orange);
    position: absolute;
}`, "",{"version":3,"sources":["webpack://./src/Components/Toggle/Toggle.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,+BAA+B;IAC/B,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;AACtB","sourcesContent":[".toggle{\n    display: flex;\n    justify-content: space-between;\n    border: 3px solid var(--orange);\n    border-radius: 1rem;\n    position: relative;\n    padding: 2px;\n    cursor: pointer;\n}\n\n.toggle>*{\n    width: 1rem;\n    height: 1rem;\n    color: var(--orange);\n}\n\n.t-button{\n    border-radius: 100%;\n    background: var(--orange);\n    position: absolute;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
