// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n-wrapper{
    position:fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    padding: 0 70px;
    background-color: white;
    box-sizing: border-box;
    z-index: 1000;
}

.n-left{
    flex: 1 1;
    align-items: center;
    display: flex;
    gap: 2rem;
}

.n-name{
    font-size: 1.3rem;
    font-weight: bold;
}

.n-right{
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    font-weight: 400;
}

.n-list{
    flex: 10 1;
}

.n-list>ul{
    list-style-type: none;
    display: flex;
    gap: 2rem;
    margin-right: 4rem;
    cursor: pointer;
}

.n-list>ul>li:hover{
    cursor: pointer;
    color: var(--orange);
}

@media screen and (max-width: 480px) {
    .n-wrapper{
        padding: 0 20px;
    }
    .n-right{
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,MAAM;IACN,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,uBAAuB;IACvB,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,SAAO;IACP,mBAAmB;IACnB,aAAa;IACb,SAAS;AACb;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,SAAO;IACP,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,UAAQ;AACZ;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,SAAS;IACT,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".n-wrapper{\n    position:fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    height: 10vh;\n    display: flex;\n    justify-content: space-between;\n    padding: 0 70px;\n    background-color: white;\n    box-sizing: border-box;\n    z-index: 1000;\n}\n\n.n-left{\n    flex: 1;\n    align-items: center;\n    display: flex;\n    gap: 2rem;\n}\n\n.n-name{\n    font-size: 1.3rem;\n    font-weight: bold;\n}\n\n.n-right{\n    display: flex;\n    flex: 1;\n    align-items: center;\n    justify-content: center;\n    font-weight: 400;\n}\n\n.n-list{\n    flex: 10;\n}\n\n.n-list>ul{\n    list-style-type: none;\n    display: flex;\n    gap: 2rem;\n    margin-right: 4rem;\n    cursor: pointer;\n}\n\n.n-list>ul>li:hover{\n    cursor: pointer;\n    color: var(--orange);\n}\n\n@media screen and (max-width: 480px) {\n    .n-wrapper{\n        padding: 0 20px;\n    }\n    .n-right{\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
