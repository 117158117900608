// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floatingdiv{
    justify-content: space-around;
    display: flex;
    background: white;
    box-shadow: var(--boxShadow);
    border-radius: 17px;
    align-items: center;
    padding: 0px 28px 0px 0px;
    height: 4.5rem;
}

.floatingdiv>img{
    transform: scale(0.4);
}

.floatingdiv>span{
    font-family: sans-serif;
    font-size: 16px;
    color: black;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/FloatingDiv/Floating.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,aAAa;IACb,iBAAiB;IACjB,4BAA4B;IAC5B,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;IACvB,eAAe;IACf,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".floatingdiv{\n    justify-content: space-around;\n    display: flex;\n    background: white;\n    box-shadow: var(--boxShadow);\n    border-radius: 17px;\n    align-items: center;\n    padding: 0px 28px 0px 0px;\n    height: 4.5rem;\n}\n\n.floatingdiv>img{\n    transform: scale(0.4);\n}\n\n.floatingdiv>span{\n    font-family: sans-serif;\n    font-size: 16px;\n    color: black;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
