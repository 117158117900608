// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer{
    display: flex;
    align-items: center;
    margin: -0.5rem -3.5rem;
    position: relative;
    margin-top: -7rem;
}

.f-content{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 4rem;
    gap: 4rem;
    color: white;
}

.f-icons{
    display: flex;
    gap: 2rem;
}

@media screen and (max-width: 480px) {
    .f-content{
        transform: scale(0.5);
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,WAAW;IACX,gBAAgB;IAChB,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".footer{\n    display: flex;\n    align-items: center;\n    margin: -0.5rem -3.5rem;\n    position: relative;\n    margin-top: -7rem;\n}\n\n.f-content{\n    position: absolute;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    width: 100%;\n    margin-top: 4rem;\n    gap: 4rem;\n    color: white;\n}\n\n.f-icons{\n    display: flex;\n    gap: 2rem;\n}\n\n@media screen and (max-width: 480px) {\n    .f-content{\n        transform: scale(0.5);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
